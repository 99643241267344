export const experienceData = [
    {
        id: 1,
        company: 'SciTech Application',
        jobtitle: 'Desenvolvedora de Software',
        startYear: '2019',
        endYear: 'Presente'
    },
    {
        id: 2,
        company: 'Vale S.A.',
        jobtitle: 'Técnica em Controle de Integrado I',
        startYear: '2021',
        endYear: 'Presente'
    },
    {
        id: 3,
        company: 'Secretaria de Ciência e Tecnologia do Maranhão - SECTI MA',
        jobtitle: 'Estagiária',
        startYear: '2020',
        endYear: '2021'
    },
    {
        id: 4,
        company: 'Universidade Federal do Maranhão - UFMA',
        jobtitle: 'Pesquisadora',
        startYear: '2020',
        endYear: '2021'
    },
]