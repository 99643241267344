export const socialsData = {
    github: 'https://github.com/sophiapinto',
    linkedIn: 'https://www.linkedin.com/in/sarah-sophia-pinto-9031081a9/',
    instagram: 'https://www.instagram.com/yllion.com.br/',
    lattes:'http://lattes.cnpq.br/0461821607737727',
    medium: 'https://medium.com/@sarahsophiapinto'
    
    /*
    facebook: 'https://www.facebook.com/',
    codepen: 'https://codepen.io/',
    twitter: 'https://twitter.com/',
    reddit: 'https://www.reddit.com/user/',
    blogger: 'https://www.blogger.com/',
    medium: 'https://medium.com/@',
    stackOverflow: 'https://stackoverflow.com/users/',
    gitlab: 'https://gitlab.com/',
    youtube: 'https://youtube.com/'
    */
}