import ebook from '../assets/pdf/ebook.pdf'
import perfil from '../assets/png/perfil.jpeg'

export const headerData = {
    name: 'Sarah Sophia Pinto',
    title: "Desenvolvedora de Software",
    desciption:"Apaixonada por Ciência, Tecnologia e Inovação. Focada em aplicação de metodologias ágeis na resolução de problemas e inovação de processos.",
    image: perfil,
    resumePdf: ebook
}
