import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import eight from '../assets/svg/projects/eight.svg'

/*
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'

import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
*/

export const projectsData = [
    {
        id: 1,
        projectName: 'InovOnline',
        projectDesc: 'Plataforma de ensino online do projeto Maratoninha Maker.',
        tags: ['React', 'Styled Components', 'NodeJS', 'Sequelize'],
        code: '',
        demo: 'https://inovonline.vercel.app/',
        image: eight
    },
    {
        id: 2,
        projectName: 'ACM Engenharia Civil',
        projectDesc: 'Portfólio online da empresa de Engenharia Civil: ACM.',
        tags: ['Java Script', 'SCSS'],
        code: '',
        demo: 'https://acmengenhariacivil.vercel.app/',
        image: two
    },
    {
        id: 3,
        projectName: 'VacBook',
        projectDesc: 'Ferramenta para verificação de aprazamento de vacinas.',
        tags: ['REACT', 'PureComponents', 'NodeJS'],
        code: 'https://github.com/sophiapinto/projeto',
        demo: 'https://vacbook.vercel.app/',
        image: eight
    },
    {
        id: 4,
        projectName: 'Poupachat',
        projectDesc: 'Chatbot online para o ensino de Educação Financeira, desenvolvido no Maria vai com as Devs do LuizaLabs.',
        tags: ['ChatBot', 'IA'],
        code: '',
        demo: 'https://sarahsophiapinto-d04de.chat.blip.ai/?appKey=cG91cGFjaGF0Ojg1MjRiMDVmLTI3MzMtNDJmMS1hZDI0LWIyMzNjZGY2NjU5Zg==',
        image: one
    },
    ]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/