export const educationData = [
    {
        id: 1,
        institution: 'Instituto Federal do Maranhão - IFMA',
        course: 'Técnico em Eletrônica',
        startYear: '2017',
        endYear: '2019'
    },
    {
        id: 2,
        institution: 'SENAI - Maranhão',
        course: 'Técnico em Controle Integrado',
        startYear: '2021',
        endYear: '2021'
    },
    {
        id: 3,
        institution: 'Universidade Federal do Maranhão - UFMA',
        course: 'Bacharelado Interdisciplinar em Ciência e Tecnologia - BICT',
        startYear: '2019',
        endYear: 'Presente'
    },
]