export const blogData = [
    {
        id: 1,
        title: 'TÉCNICAS DE INTELIGÊNCIA ARTIFICIAL APLICÁVEIS À PESQUISA E AO DESENVOLVIMENTO DE MATERIAIS AVANÇADOS PARA BATERIAS RECARREGÁVEIS: BREVE REVISÃO DE LITERATURA',
        description: 'III Simpósio REACT sobre Descarbonização: economia, energia e ambiente.',
        date: '06-09-2023',
        url: 'https://www.even3.com.br/anais/simposioreact2023/678098-tecnicas-de-inteligencia-artificial-aplicaveis-a-pesquisa-e-ao-desenvolvimento-de-materiais-avancados-para-bateri/'
    },
    {
        id: 2,
        title: 'PROPOSTA DE SOLUÇÃO TECNOLÓGICA VOLTADA PARA REALIZAÇÃO DE APRAZAMENTO VACINAL PELO ENFERMEIRO DA ATENÇÃO BÁSICA',
        description: 'Anais da Semana de Engenharia do Maranhão',
        date: '11-12-2023',
        url: 'https://www.even3.com.br/anais/xisengema/696786-proposta-de-solucao-tecnologica-voltada-para-realizacao-de-aprazamento-vacinal-pelo-enfermeiro-da-atencao-basica/'
    },
    {
        id: 3,
        title: 'UTILIZAÇÃO DE DRONES PARA IDENTIFICAÇÃO DE PATOLOGIAS E CONSTRUÇÃO DE MODELAGEM 3D DE FACHADAS HISTÓRICAS EM SÃO LUÍS - MA (MONOGRAFIA)',
        description: 'Universidade Federal do Maranhão - UFMA',
        date: '22-12-2023',
        url: 'https://drive.google.com/file/d/1X_BKEAjxlQKmO3t8S0tt77poypSDOCIz/view'
    }
]

// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/